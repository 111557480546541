import React, {useEffect, useState} from "react";

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'

import appIcona from './immagini/appIcona.png';

import './App.css';

function Prezzi(props) {

    function Nota(props) {
        return (<sup><span style={{fontSize:11}}>({props.numero})</span></sup>);
    }

    return (
        <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    fontSize:16,
                    lineHeight:1.4                
                }}
            >
                Il software è a disposizione gratuitamente per tutti gli iscritti alle Società che utilizzano Gestione Sportiva!
                <br/><br/>
                <b>E' semplice:<br/>
                dal telefono, clicca qui sopra sul bottone ACCEDI.</b>
                <br/>
                L'applicazione stessa spiega come aggiungere l'ICONA nel tuo telefono in modo tale che sia una APP come tutte le altre che hai già installato nel tuo device.<br/><br/>
                Dopo il primo accesso, si ricorderà i tuoi dati di accesso e per usarla sarà sufficiente premere sull'Icona Gestione Sportiva!<br/><br/>
                    
            </div>

            <FondoPagina {...props}/>
        </div>
    )        
}

export default Prezzi;