import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'
import FormResponsive from './FormResponsive'
import Parametri from './parametri';
import Clessidra from './Clessidra';
import FormattaImportoVideo from './FormattaImportoVideo';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import './App.css';

function PayPalButton(props) {
    //const paypalUrlBackend = 'https://massbroken.dyndns.org:2003/ema/server_paypal.php';
    const paypalUrlBackend = 'https://ema1.gestionesportiva.it/server_paypal.php';
    const [errorMessage, setErrorMessage] = useState('');
    const [flagPagamentoCompletato, setFlagPagamentoCompletato] = useState(false);
  
    const createOrder = async () => {
      try {
        const response = await fetch(paypalUrlBackend, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            op: 'orders',
            cart: [
              {
                id: props.numeroOrdine,
                quantity: '1',
              },
            ],
          }),
        });
  
        const orderData = await response.json();
  
        if (orderData) {
          if (orderData.id) {
            return orderData.id;
          } else {
            const errorDetail = orderData?.details?.[0];
            const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
              : JSON.stringify(orderData);
    
            throw new Error(errorMessage);
          }
        } else {
          // Gestisci il caso in cui 'id' non è presente nell'oggetto o orderData è null
          throw new Error("orderData not found or invalid data");
        }
  
      } catch (error) {
        console.error(error);
        //setErrorMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
        setErrorMessage("Impossibile procedere al checkout Paypal");
        props.setFlagPagamentoFallito(true);
      }
    };
  
    const onApprove = async (data, actions) => {
      try {
        const response = await fetch(paypalUrlBackend, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            op: 'capture',
            orderID: data.orderID
          }),
        });
  
        const orderData = await response.json();
  
        const errorDetail = orderData?.details?.[0];
  
        if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
          return actions.restart();
        } else if (errorDetail) {
          throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
        } else if (!orderData.purchase_units) {
          throw new Error(JSON.stringify(orderData));
        } else {
          const transaction =
            orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
            orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
          setErrorMessage(
            `Transazione ${transaction.status}: ${transaction.id}`,
          );
          if (transaction.status == "COMPLETED") {
            setFlagPagamentoCompletato(true);
            props.setFlagPagamentoCompletato(true);
            props.setPaypalTransactionId(transaction.id);
          }
          console.log(
            'Capture result',
            orderData,
            JSON.stringify(orderData, null, 2),
          );
        }
      } catch (error) {
        console.error(error);
        // setErrorMessage(`Sorry, your transaction could not be processed...<br><br>${error}`);
        props.setFlagPagamentoFallito(true);
        setErrorMessage("Transazione Paypal FALLITA");
      }
    };
  
    return (
      <div>
        {!flagPagamentoCompletato && <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
        />}
        <div id="result-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </div>
    );
  }

function Acquista(props) {
    var giorni=[];
    for (var g=1; g<=31; g++) giorni.push(g);
    const mesi=["gennaio","febbraio","marzo","aprile","maggio","giugno","luglio","agosto","settembre","ottobre","novembre","dicembre"];

    const comandoNavigazione = useNavigate();

    const [flagAttesa, setFlagAttesa] = useState(true);
    const [flagRegistrato, setFlagRegistrato] = useState(false);
    const [flagEvidenziaRichiestaConsenso, setFlagEvidenziaRichiestaConsenso] = useState(false);
    const [sessionId, setSessionId] = useState("");
    const [giornoChiusuraEsercizio, setGiornoChiusuraEsercizio] = useState(31);
    const [meseChiusuraEsercizio, setMeseChiusuraEsercizio] = useState(11); // mesi da 0 a 11 attenzione!
    const [flagConsenso, setFlagConsenso] = useState(false);
    const [valoriCampiObbligatoriForm, setValoriCampiObbligatoriForm] = useState({});
    const [valoriCampiFacoltativiForm, setValoriCampiFacoltativiForm] = useState({});
    const [listino, setListino] = useState([]);
    const [pacchettoSelezionato, setPacchettoSelezionato] = useState("");
    const [pacchettiAggiuntivi, setPacchettiAggiuntivi] = useState("");
    const [codiceSconto, setCodiceSconto] = useState("");
    const [percentualeSconto, setPercentualeSconto] = useState(0);
    const [messaggioEsitoRicercaSconto, setMessaggioEsitoRicercaSconto] = useState("");
    const [numeroOrdine, setNumeroOrdine] = useState("");
    const [importoOrdine, setImportoOrdine] = useState(0);
    const [flagPagamentoCompletato, setFlagPagamentoCompletato] = useState(false);
    const [flagPagamentoFallito, setFlagPagamentoFallito] = useState(false);
    const [paypalTransactionId, setPaypalTransactionId] = useState("");

    const elencoCampiObbligatori = [
        { label: 'Indirizzo Email:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Tuo cognome:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Tuo nome:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Nome società:', type: 'text', initialWidth: 250, minWidth: 250 },
        { label: 'P.IVA società:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Codice fiscale società:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Indirizzo società:', type: 'text', initialWidth: 350, minWidth: 350 },
        { label: 'CAP società:', type: 'text', initialWidth: 50, minWidth: 50 },
        { label: 'Città società:', type: 'text', initialWidth: 150, minWidth: 150 },
    ];

    const elencoCampiFacoltativi = [
        { label: 'Codice Destinatario per ricevere Fattura Elettronica:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Posta elettronica certificata (indirizzo PEC):', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Telefono:', type: 'text', initialWidth: 150, minWidth: 150 },
    ];

    useEffect(() => {
        setSessionId("");
        setFlagConsenso(false);
        setValoriCampiObbligatoriForm({});
        setValoriCampiFacoltativiForm({});
        preflight();
    },[])

    function vaiLink(link) {
        comandoNavigazione(link);
        window.scrollTo(0, 0);
    }    

    function verificaEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
        return emailRegex.test(email);
    }

    function preflight() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"preflight", "listino":1})
        };

        fetch(Parametri("serverURL"), requestOptions).then(risposta => risposta.json()).then(
            (valoreDiRitorno) => {
                setFlagAttesa(false);
                if (valoreDiRitorno.risultatoOperazione!==1) {
                    alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                    vaiLink("/")
                } else {
                    setListino(valoreDiRitorno.listino);
                    setPacchettoSelezionato(valoreDiRitorno.listino[0].CODICE_ARTICOLO);
                    setSessionId(valoreDiRitorno.sessionId);
                }
            },
            (error) => {
                setFlagAttesa(false);
                alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                vaiLink("/")
            }
        ) 
    }    

    function applicaSconto() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"applicaSconto",
                sessionId:sessionId,
                codiceSconto:codiceSconto
            })
        };
        setMessaggioEsitoRicercaSconto("Attendi la verifica del Codice Sconto...");
        fetch(Parametri("serverURL"), requestOptions).then(risposta => risposta.json()).then(
            (valoreDiRitorno) => {
                setMessaggioEsitoRicercaSconto("");
                if (valoreDiRitorno.risultatoOperazione!==1) {
                    if (valoreDiRitorno.errore != "") {
                        alert("Errore: " + valoreDiRitorno.errore + ". Per assistenza, contattaci all'indirizzo "+props.emailAssistenza);    
                    } else {
                        alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                    }
                    return;
                }
                setPercentualeSconto(valoreDiRitorno.percentualeSconto);                
            },
            (error) => {
                setMessaggioEsitoRicercaSconto("");
                alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
            }
        ) 
    }

    function inviaDati() {
        if (!flagConsenso) {
            setFlagEvidenziaRichiestaConsenso(true);
            alert("Spunta il consenso al trattamento dati per proseguire, senza quello non possiamo memorizzare i dati che hai inserito");
            return;
        }

        if (valoriCampiObbligatoriForm.input_0=="" || !verificaEmail(valoriCampiObbligatoriForm.input_0)) {
            alert("Verifica l'indirizzo email");
            return;
        }
        if (valoriCampiObbligatoriForm.input_1===undefined || valoriCampiObbligatoriForm.input_1=="") {
            alert("Inserisci il cognome");
            return;
        }
        if (valoriCampiObbligatoriForm.input_2===undefined || valoriCampiObbligatoriForm.input_2=="") {
            alert("Inserisci il nome");
            return;
        }
        if (valoriCampiObbligatoriForm.input_3===undefined || valoriCampiObbligatoriForm.input_3=="") {
            alert("Inserisci il nome della società");
            return;
        }
        if ((valoriCampiObbligatoriForm.input_4===undefined || valoriCampiObbligatoriForm.input_4=="") && (valoriCampiObbligatoriForm.input_5===undefined || valoriCampiObbligatoriForm.input_5=="")) {
            alert("Inserisci la Partita IVA o il Codice Fiscale della società");
            return;
        }
        if (valoriCampiObbligatoriForm.input_6===undefined || valoriCampiObbligatoriForm.input_6=="") {
            alert("Inserisci l'indirizzo della società");
            return;
        }
        if (valoriCampiObbligatoriForm.input_7===undefined || valoriCampiObbligatoriForm.input_7=="") {
            alert("Inserisci il CAP della società");
            return;
        }
        if (valoriCampiObbligatoriForm.input_8===undefined || valoriCampiObbligatoriForm.input_8=="") {
            alert("Inserisci la città della società");
            return;
        }
        
        setFlagAttesa(true);

        var p = pacchettoSelezionato;
        if (pacchettiAggiuntivi != "") p = p + ";" + pacchettiAggiuntivi;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"acquista",
                sessionId:sessionId,
                consenso: flagConsenso?1:0,
                email:valoriCampiObbligatoriForm.input_0,
                cognome:valoriCampiObbligatoriForm.input_1,
                nome:valoriCampiObbligatoriForm.input_2,
                societa:valoriCampiObbligatoriForm.input_3,
                piva:valoriCampiObbligatoriForm.input_4,
                codfiscale:valoriCampiObbligatoriForm.input_5,
                indirizzo:valoriCampiObbligatoriForm.input_6,
                cap:valoriCampiObbligatoriForm.input_7,
                citta:valoriCampiObbligatoriForm.input_8,
                codiceDestinatario:valoriCampiFacoltativiForm.input_0,
                emailPEC:valoriCampiFacoltativiForm.input_1,
                telefono:valoriCampiFacoltativiForm.input_2,
                giornoChiusuraEsercizio:giornoChiusuraEsercizio,
                meseChiusuraEsercizio:parseInt(meseChiusuraEsercizio)+1,
                pacchettoSelezionato:p,
                codiceSconto:codiceSconto
            })
        };

        fetch(Parametri("serverURL"), requestOptions).then(risposta => risposta.json()).then(
            (valoreDiRitorno) => {
                setFlagAttesa(false);
                if (valoreDiRitorno.risultatoOperazione!==1) {
                    if (valoreDiRitorno.errore != "") {
                        alert("Errore: " + valoreDiRitorno.errore + ". Per assistenza, contattaci all'indirizzo "+props.emailAssistenza);    
                    } else {
                        alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                    }
                    return;
                }
                if (valoreDiRitorno.numero_ordine == "") {
                    setFlagAttesa(false);
                    alert("Numero d'ordine non generato, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                    return;
                }
                setFlagRegistrato(true);
                setNumeroOrdine(valoreDiRitorno.numero_ordine);
                setImportoOrdine(valoreDiRitorno.importo);
            },
            (error) => {
                setFlagAttesa(false);
                alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
            }
        ) 
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function verificaSelezionePacchettoAggiuntivo(p) {
        var ar = pacchettiAggiuntivi.split(';');
        for (var n=0; n<ar.length; n++) if (ar[n] == p) return true;
        return false;
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function aggiungiRimuoviPacchettoAggiuntivo(p) {
        var pv;
        var aggiungi = true;
        var ar = pacchettiAggiuntivi.split(';');
        for (var n=0; n<ar.length; n++) {
            if (ar[n] == p) {
                aggiungi = false;
                break;
            }
        }
        if (aggiungi) {
            pv = pacchettiAggiuntivi;
            if (pv != "") pv = pv + ";";
            pv = pv + p;            
        } else {
            pv = "";
            for (var n=0; n<ar.length; n++) {
                if (ar[n] != p) {
                    if (pv != "") pv = pv + ";";
                    pv = pv + ar[n];
                }
            }        
        }
        setPacchettiAggiuntivi(pv);
    }

    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function ottieniImportoNettoPacchetto(p) {
        for (var n=0; n<listino.length; n++) {
            if (listino[n].CODICE_ARTICOLO == p) return listino[n].IMPORTO_NETTO;
        }
        return 0;
    }
    
    // funzione presente in SchedaMioAbbonamento di EMA e Acquista di EMASITO
    function calcolaTotaleImportoPacchettiSelezionati() {
        var ar = pacchettiAggiuntivi.split(';');
        var i = 0;        
        if (pacchettoSelezionato!="") i = parseFloat(ottieniImportoNettoPacchetto(pacchettoSelezionato));
        for (var n=0; n<ar.length; n++) i = i + parseFloat(ottieniImportoNettoPacchetto(ar[n]));
        // applico lo sconto
        if (percentualeSconto > 0) i -= i * percentualeSconto / 100;
        // arrotondo il netto a 2 decimali
        i = Math.round(i * 100) / 100;
        // calcolo il lordo
        i = i * 1.22;
        // arrotondo il lordo a 2 decimali
        i = Math.round(i * 100) / 100;

        return FormattaImportoVideo(i);
    }    
    
    if (flagAttesa) {
        return (<Clessidra top={window.innerHeight/2} loading={true}/>)
    }

    if (flagRegistrato) {
        return (
            <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    cursor:"pointer",
                    fontSize:16,
                    lineHeight:1.4                
                }}
            >
                { flagPagamentoCompletato ?
                    <>
                        Grazie per aver effettuato e pagato l'ordine {numeroOrdine}, Transaction ID Paypal: {paypalTransactionId}
                        <br/>
                        Riceverai a breve una mail con le indicazioni per accedere all'applicazione.<br/>
                        Se non la ricevi entro 2 giorni lavorativi, cortesemente contattaci all'indirizzo assistenza@gestionesportiva.it
                    </>
                :
                    <>
                        Grazie per aver effettuato la richiesta di acquisto, è stato generato l'ordine {numeroOrdine}.
                        <br/>
                        {flagPagamentoFallito && 
                        <div style={{backgroundColor:"#FF2020", color:"#FFFF00", width:"100%", paddingTop:5, paddingBottom:5, marginTop: 5, marginBottom:5}}><b>PAGAMENTO PAYPAL FALLITO</b></div>
                        }
                        Puoi procedere con il pagamento con PayPal o con la carta di credito, oppure puoi pagare con bonifico bancario:<br/><br/>
                        <div 
                            style={{
                                backgroundColor:"#F5F5F5", 
                                marginTop:20, 
                                marginBottom:10, 
                                paddingTop:8, 
                                paddingBottom:8, 
                                paddingLeft:20, 
                                paddingRight:20, 
                                border: "1px solid black"
                            }}                    
                        >
                        Dati per pagare con bonifico bancario:<br/><br/>
                        <b>IBAN: IT67G0301503200000003931391</b><br/>
                        intestato a: Azienda Informatica Genova Srl<br/>
                        <b>importo: {FormattaImportoVideo(importoOrdine)} euro</b><br/>
                        <b>causale: {numeroOrdine}</b>
                        </div>

                        <div 
                            style={{
                                backgroundColor:"#F5F5F5", 
                                marginTop:20, 
                                marginBottom:10, 
                                paddingTop:8, 
                                paddingBottom:8, 
                                paddingLeft:20, 
                                paddingRight:20, 
                                border: "1px solid black"
                            }}                    
                        >
                            Per pagare subito con PayPal o Carta di Credito:<br/><br/>
                            <PayPalScriptProvider options={{ 'client-id': 'AQPplAdeAWZxZW-t4uJym90JrTEJiw6LcnYxcYapA9ogTBnvkaScuCDOIvFcPFAEgC05YeX29d0o6qpK', 'currency':'EUR' }}>
                                <PayPalButton 
                                    numeroOrdine={numeroOrdine} 
                                    setFlagPagamentoCompletato={setFlagPagamentoCompletato} 
                                    setFlagPagamentoFallito={setFlagPagamentoFallito}
                                    setPaypalTransactionId={setPaypalTransactionId}
                                />
                            </PayPalScriptProvider>
                        </div>

                        <div 
                            style={{
                                backgroundColor:"#F5F5F5", 
                                marginTop:20, 
                                marginBottom:10, 
                                paddingTop:8, 
                                paddingBottom:8, 
                                paddingLeft:20, 
                                paddingRight:20, 
                                border: "1px solid black"
                            }}                    
                        >
                        Dati per pagare in seguito con trasferimento PayPal:<br/><br/>
                        <b>Email: info@aigenova.it<br/>
                        <b>importo: {FormattaImportoVideo(importoOrdine)} euro</b><br/>
                        causale: {numeroOrdine}     
                        </b>
                        </div>
                    </>
                }

            </div>

            </div>
        )
    }

    return (
        <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    cursor:"pointer",
                    fontSize:16,
                    lineHeight:1.4                
                }}
            >
                <b>Compila i campi qui sotto e clicca su "Conferma" per acquistare il prodotto, se non hai già accesso al servizio</b>

                <br/>
                <span style={{fontStyle:'italic', fontSize:14}}>
                    Non daremo i tuoi dati ad altri, li useremo solo per fornirti il servizio o contattarti e li cancelleremo al termine del servizio.<br/>
                    Se sei cliente o hai il servizio in prova gratuita, per acquistare, rinnovare o modificare l'abbonamento accedi al servizio con il bottone "Accedi" posto in alto in questa pagina e poi vai alla voce di menu Impostazioni - Mio Abbonamento.
                </span>
                <br/>
                <div style={{                        
                    border:"1px solid #202020", 
                    textAlign:'left', 
                    paddingLeft:10, 
                    paddingBottom:10,
                    width:'90%',
                    backgroundColor:'#FFFFFF', 
                    boxShadow: "1.5px 1.5px #B0B0B0"}}>
                    <span style={{fontSize:13, fontWeight:"bold", color:"#006fe0"}}>Dati necessari</span><br/>
                    <FormResponsive 
                        stile={{border:"1px solid #C0C0C0",fontSize:16,backgroundColor:"#F0F0F0"}}
                        elencoCampi={elencoCampiObbligatori}
                        valoriCampiForm={valoriCampiObbligatoriForm}
                        setValoriCampiForm={setValoriCampiObbligatoriForm}
                    />
                    
                    <div style={{display:"flex",flexDirection:"row", marginTop:10, fontStyle:'italic'}}>
                        Consenso al trattamento dei dati:
                    </div>
                    <div style={{display:"flex",flexDirection:"row", marginTop:5, marginBottom:10}}>
                        <div>
                            <input type='checkbox' checked={flagConsenso || false} onChange={e => { setFlagEvidenziaRichiestaConsenso(false); setFlagConsenso(e.target.checked)}}/>
                        </div>
                        <div style={{width:10}}/>
                        <div style={{flex:1, backgroundColor:flagEvidenziaRichiestaConsenso?"#FFC0C0":""}}>
                            Dichiaro di avere legalmente accesso all'indirizzo email indicato; accetto che tutti i dati inseriti in questa pagina e tutti i dati che inserirò nell'applicazione vengano memorizzati da {props.produttore.ragioneSociale} e mantenuti per tutta la durata della prova del servizio e dell'eventuale successivo acquisto.
                        </div>
                        <div style={{width:10}}/>
                    </div>

                    <div style={{display:"flex",flexDirection:"row", marginTop:10, fontStyle:'italic'}}>
                        Seleziona&nbsp;<b>uno</b>&nbsp;tra i seguenti&nbsp;<span style={{color:"#FF0000", fontWeight:'bold'}}>pacchetti base</span>:
                    </div>                
                    
                    {listino.map((elemento, indice) => elemento.ACQUISTABILE==1 &&                        
                        <div key={indice} style={{display:'flex'}}>                            
                            <label>
                                <input type='checkbox' 
                                    name='pacchettobase' 
                                    value={elemento.CODICE_ARTICOLO} 
                                    checked={pacchettoSelezionato === elemento.CODICE_ARTICOLO}                                         
                                    onChange={e => { setPacchettoSelezionato(e.target.value)}}
                                />
                                <span style={{marginLeft:10, cursor:'pointer'}}>
                                    {elemento.DESCRIZIONE}
                                    , durata: {elemento.ACQUISTABILE==3 ? "fino al termine dell'abbonamento attuale" : elemento.DURATA_VALORE+" "+elemento.DURATA_UNITA_MISURA}
                                    , iscritti: {elemento.LIMITE_ISCRITTI}
                                    , spazio: {elemento.LIMITE_MB} {"MB"}
                                    , importo: {FormattaImportoVideo(elemento.IMPORTO_NETTO)} euro + IVA
                                </span>
                            </label>                            
                        </div>                        
                    )}

                    <div style={{display:"flex",flexDirection:"row", marginTop:10, fontStyle:'italic'}}>
                        Seleziona, se vuoi,&nbsp;<span style={{color:"#FF0000", fontWeight:'bold'}}>pacchetti aggiuntivi</span>&nbsp; opzionali tra i seguenti (potrai aggiungerli anche in seguito):
                    </div>                

                    {listino.map((elemento, indice) => elemento.ACQUISTABILE==3 &&                        
                        <div key={indice} style={{display:'flex'}}>                            
                            <label>
                                <input type='checkbox' 
                                    name='pacchettiaggiuntivi' 
                                    value={elemento.CODICE_ARTICOLO} 
                                    checked={verificaSelezionePacchettoAggiuntivo(elemento.CODICE_ARTICOLO)}  
                                    onChange={e => { aggiungiRimuoviPacchettoAggiuntivo(e.target.value)}}
                                />
                                <span style={{marginLeft:10, cursor:'pointer'}}>
                                    {elemento.DESCRIZIONE}
                                    , iscritti: {elemento.LIMITE_ISCRITTI}
                                    , spazio: {elemento.LIMITE_MB} {"MB"}
                                    , importo: {FormattaImportoVideo(elemento.IMPORTO_NETTO)} euro + IVA
                                </span>
                            </label>                            
                        </div>                        
                    )}             

                    <br/>
                    <i>Se hai un <span style={{color:"#FF0000", fontWeight:'bold'}}>Codice Sconto</span> inseriscilo qui e poi premi il bottone a fianco per applicare lo sconto:</i>
                    <br/>
                    
                    <div style={{display:'flex', flexDirection: 'row'}}>                        
                        <div style={{
                            display: 'flex', 
                            alignItems: 'center', 
                        }}>
                            Codice Sconto:&nbsp;<input type='text' size="15" style={{border:"1px solid #C0C0C0",fontSize:16,backgroundColor:"#F0F0F0"}} onChange={(e)=>setCodiceSconto(e.target.value)}/>
                        </div>
                        <div 
                            style={{
                                backgroundColor:"#006fe0", 
                                color:'#f0f0f0',                         
                                marginLeft:10, 
                                paddingTop:8, 
                                paddingBottom:8, 
                                paddingLeft:20, 
                                paddingRight:20, 
                                cursor:'pointer'
                            }}
                            onClick={() => applicaSconto()}
                        >
                            Applica Sconto
                        </div>
                    </div>
                    
                    <br/>
                    <i>Se non hai un Codice Sconto, contattaci: <a href= "mailto:info@gestionesportiva.it">info@gestionesportiva.it</a></i>
                    <br/>
                    <br/>

                    {percentualeSconto > 0 && <div>Sconto applicato: {percentualeSconto} %<br/><br/></div>}
                    {percentualeSconto < 0 && <div>Errore nella ricerca del codice sconto (Err #{-percentualeSconto}).<br/><br/></div>}
                    {messaggioEsitoRicercaSconto != "" && <div>{messaggioEsitoRicercaSconto}.<br/><br/></div>}

                    <span style={{fontSize:20, fontWeight:'bold'}}>Importo totale: € {calcolaTotaleImportoPacchettiSelezionati()} IVA inclusa.</span>
                    <br/><br/>
                    Pagamento: PayPal, carta di credito o bonifico bancario (potrai scegliere nella prossima pagina).

                </div>
                
                <div style={{                        
                    border:"1px solid #202020", 
                    textAlign:'left',
                    marginTop:20, 
                    paddingLeft:10, 
                    width:'90%',
                    backgroundColor:'#FFFFFF', 
                    boxShadow: "1.5px 1.5px #B0B0B0"}}>
                    
                    <span style={{fontSize:13, fontWeight:"bold", color:"#01a500"}}>
                        Dati facoltativi
                    </span><br/>
                    <FormResponsive 
                        stile={{border:"1px solid #C0C0C0",fontSize:16,backgroundColor:"#F0F0F0"}}
                        elencoCampi={elencoCampiFacoltativi}
                        valoriCampiForm={valoriCampiFacoltativiForm}
                        setValoriCampiForm={setValoriCampiFacoltativiForm}
                    />

                    <div style={{display:"flex",flexDirection:"row", marginTop:10, marginBottom:10, marginRight:15}}>
                        {props.dimensioniApp.flagPaginaOrizzontale ? "Data di chiusura esercizio (normalmente è il 31 dicembre):"
                        : "Chiusura esercizio:" }
                        <div style={{width:10}}/>
                        <div>
                            <select style={{border:"1px solid #C0C0C0", fontSize:16, backgroundColor:"#F0F0F0"}} onChange={e => setGiornoChiusuraEsercizio(e.target.value)}>
                                {giorni.map((giorno,indice)=>
                                    giorno==giornoChiusuraEsercizio ? 
                                        <option key={indice} selected value={giorno}>{giorno}</option>
                                    :
                                        <option key={indice} value={giorno}>{giorno}</option>
                                    )}                                    
                            </select>
                        </div>
                        <div style={{width:10}}/>
                        <div>
                            <select style={{border:"1px solid #C0C0C0", fontSize:16, backgroundColor:"#F0F0F0"}} onChange={e => setMeseChiusuraEsercizio(e.target.value)}>
                                {mesi.map((mese,indice)=>
                                    indice==meseChiusuraEsercizio ?
                                        <option key={indice} selected value={indice}>{mese}</option>
                                    :
                                        <option key={indice} value={indice}>{mese}</option>
                                    )}
                            </select>
                        </div>
                        <div style={{flex:1}}></div>
                    </div>
                </div>

                <div 
                    style={{
                        backgroundColor:"#006fe0", 
                        color:'#f0f0f0',                         
                        marginTop:20, 
                        marginBottom:10, 
                        paddingTop:8, 
                        paddingBottom:8, 
                        paddingLeft:20, 
                        paddingRight:20, 
                        cursor:'pointer'
                    }}
                    onClick={() => inviaDati()}
                >
                    Conferma
                </div>

            </div>
            
            <FondoPagina {...props}/>
        </div>
    )        
}

export default Acquista;