import React, {useEffect, useState} from "react";

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'

import './App.css';
import './Sconto.css';

function Nota(props) {
    return (<sup><span style={{fontSize:11}}>({props.numero})</span></sup>);
}

const PianoAcquisto = ({ nome, prezzo, descrizione }) => {
    const sconto = 0.30;
    var prezzoScontato = "";
    var prezzoOriginale = "";
    if (!isNaN(prezzo)) {
        prezzoOriginale = (prezzo.toFixed(2) + " €").replace(".",",");
        prezzoScontato = ((prezzo - (prezzo * sconto)).toFixed(2) + " €/mese").replace(".",",");
    }
    return (
      <div className="piano-card">
        <h2>{nome}</h2>
        {descrizione && <p className="descrizione">{descrizione}</p>}
        <div className="prezzi">          
          {prezzoScontato != "" ? <>
            <span className="prezzo-originale">{prezzoOriginale}</span>
            <span className="sconto">-30%</span>
            <span className="prezzo-scontato">{prezzoScontato}</span>
            <span className="descrizione">+ IVA <Nota numero={3}/></span>
            </>
          :
            <>
            <span className="prezzo-scontato">{prezzo}</span>
            <span className="descrizione"><a href= "mailto:info@gestionesportiva.it">Contattaci: info@gestionesportiva.it</a></span>
            </>
          }
        </div>
      </div>
    );
  };
  
  const PianiStandard = () => {
    const piani = [
        { nome: 'Piano Small', prezzo: 10, descrizione: (
            <span>tutte le funzionalità <Nota numero={1}/><br/>fino a 30 iscritti <Nota numero={2} /><br/>fino a 60 MB</span>
        )},
        { nome: 'Piano Medium', prezzo: 20, descrizione: (
            <span>tutte le funzionalità <Nota numero={1}/><br/>fino a 300 iscritti <Nota numero={2} /><br/>fino a 600 MB</span>
        )},
        { nome: 'Piano Large', prezzo: 32, descrizione: (
            <span>tutte le funzionalità <Nota numero={1}/><br/>fino a 500 iscritti <Nota numero={2} /><br/>fino a 1 GB</span>
        )},
        { nome: 'Piano Extra Large', prezzo: 55, descrizione: (
            <span>tutte le funzionalità <Nota numero={1}/><br/>fino a 1000 iscritti <Nota numero={2} /><br/>fino a 2 GB</span>
        )},
        { nome: 'Piano Personalizzato', prezzo: "su misura per te", descrizione: (
            <span>tutte le funzionalità <Nota numero={1}/><br/>oltre 1000 iscritti <Nota numero={2} /><br/>e/o oltre 2 GB</span>
        )},
      ];
  
    return (
      <div className="contenitore-piani">
        {piani.map((piano, index) => (
          <PianoAcquisto 
            key={index} 
            nome={piano.nome} 
            prezzo={piano.prezzo} 
            descrizione={piano.descrizione}
          />
        ))}
      </div>
    );
  };

  const PianiAggiuntivi = () => {
    const piani = [
        { nome: 'Estensione', prezzo: 20, descrizione: (            
            <span>300 iscritti aggiuntivi <Nota numero={2} /><br/>600 MB aggiuntivi</span>
        )},
        { nome: 'Personalizzazioni', prezzo: "su misura per te", descrizione: (
            <span>funzioni aggiuntive o modificate<br/>secondo le tue esigenze</span>
        )},
        { nome: 'Controllo Accessi', prezzo: "su misura per te", descrizione: (
            <span>integrazione ai lettori<br/>di schede RFID e/o di QRCode</span>
        )},
      ];
  
    return (
      <div className="contenitore-piani">
        {piani.map((piano, index) => (
          <PianoAcquisto 
            key={index} 
            nome={piano.nome} 
            prezzo={piano.prezzo} 
            descrizione={piano.descrizione}
          />
        ))}
      </div>
    );
  };
  

function Prezzi(props) {

    function Nota(props) {
        return (<sup><span style={{fontSize:11}}>({props.numero})</span></sup>);
    }

    return (
        <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>
                        
            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    fontSize:props.dimensioniApp.x>600?16:15,
                    lineHeight:1.4                
                }}
            >
                <PianiStandard/>

                <br/>
                <br/>

                <b>Sono anche disponibili:</b>
                <PianiAggiuntivi/>

                <br/><b>Gestione Sportiva comprende sempre:</b><br/>
                <div>
                tutte le funzioni dell'applicazione tranne il Controllo Accessi <Nota numero={1}/>,<br/>
                accesso senza limitazione al numero di operatori <Nota numero={4}/>,<br/>
                utilizzo dell'applicazione per tutti i tuoi clienti <Nota numero={5}/><br/>                
                </div>

                <br/><b>Metodi di pagamento:</b><br/>
                <div>
                PayPal,<br/>
                carta di credito (con transazione sicura gestita da PayPal),<br/>
                bonifico bancario<br/>
                </div>

            </div>
            
            <div style={{textAlign:'left', paddingLeft:20, paddingRight:20, paddingBottom:30}}>
                Note<br/>
                <div style={{borderBottom: "1px solid #C0C0C0", width:"100%"}}></div>
                <br/>
                <span style={{fontSize:14}}>
                (1) I piani Standard comprendono tutte le funzionalità del prodotto tranne il controllo accessi, che è un modulo da personalizzare a seconda del tipo di lettore, della struttura e delle funzioni che vuoi. Se hai già tornelli e lettori, normalmente possiamo mantenere quelli e farli funzionare con Gestione Sportiva. Contattaci per un preventivo senza impegno.<br/>
                (2) Gli iscritti che hanno lasciato la società ma vengono mantenuti negli elenchi per motivi di reportistica, o per eventualmente riattivarli in futuro, <b>contano ciascuno come metà iscritto</b>.<br/>
                (3) Per i piani, è richiesto il pagamento anticipato di 12 mesi in un'unica soluzione. Per le estensioni, è richiesto il pagamento anticipato in un'unica soluzione di un numero di mesi pari al numero di mesi necessari per arrivare alla fine del piano sottoscritto, arrotondato per eccesso.<br/>
                I prezzi scontati sopra indicati richiedono un <b><span style={{color:'#FF0000', backgroundColor:"#FFFF00", paddingLeft:"5px", paddingRight:"5px"}}>codice sconto</span></b>: se non ne sei in possesso <a href= "mailto:info@gestionesportiva.it">contattaci all'indirizzo info@gestionesportiva.it</a><br/>
                (4) Il numero massimo di operatori coincide con il numero massimo di iscritti.<br/>
                (5) Gli iscritti utilizzano l'applicazione dai loro dispositivi mobili cliccando sull'icona di Gestione Sportiva.<br/>
                </span>
            </div>

            <FondoPagina {...props}/>
        </div>
    )        
}

export default Prezzi;